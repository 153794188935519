var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vxe-table",
    {
      attrs: {
        border: "",
        stripe: "",
        "max-height": "300px",
        data: _vm.productList,
      },
    },
    [
      _c("vxe-column", {
        attrs: { field: "productCode", title: "产品编码", "show-overflow": "" },
      }),
      _c("vxe-column", {
        attrs: { field: "productName", title: "产品名称", "show-overflow": "" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }