/*
 * @Author: your name
 * @Date: 2021-11-08 11:29:24
 * @LastEditTime: 2021-11-16 11:14:24
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \crm-product（西凤产品web）\found\views\sfa\views\reports\display_fees\index.js
 */
/*
 * @Author: your name
 * @Date: 2021-11-08 11:29:24
 * @LastEditTime: 2021-11-08 11:32:13
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \crm-product（西凤产品web）\found\views\sfa\views\reports\display_fees\index.js
 */
import TablePage from '../../../../../components/table_page';
// import DatePicker from '../../../components/datePicker';
// import viewDetail from '../../../components/viewDetail.vue';
import Form from './form/index';

export default {
  name: 'table-component',
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {
      // formName: 'viewDetail',
    };
  },
  methods: {
    modalClick({
      val,
      row,
    }) {
      const {
        code,
      } = val;
      if (code === 'view') {
        this.modalConfig.title = '详情';
        this.formName = 'Form';
        this.formConfig = {
          name: 'CLF',
          code,
          row,
        };
        this.openFull();
      }
    },
  },
  created() {
    this.getConfigList('display_fees_report');
  },

};
