var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "title" }, [_vm._v("门头照")]),
      _c(
        "div",
        { staticClass: "img-wrap" },
        _vm._l(_vm.doorPhotoRespVos, function (item, index) {
          return _c(
            "div",
            { key: index },
            [
              _c("el-image", {
                staticStyle: {
                  width: "100px",
                  height: "100px",
                  "margin-right": "20px",
                },
                attrs: { src: item, "preview-src-list": _vm.doorPhotoRespVos },
              }),
            ],
            1
          )
        }),
        0
      ),
      _c("div", { staticClass: "title" }, [_vm._v("陈列照")]),
      _c(
        "div",
        { staticClass: "img-wrap" },
        _vm._l(_vm.displayPhotoRespVos, function (item, index) {
          return _c(
            "div",
            { key: index },
            [
              _c("el-image", {
                staticStyle: {
                  width: "100px",
                  height: "100px",
                  "margin-right": "20px",
                },
                attrs: {
                  src: item,
                  "preview-src-list": _vm.displayPhotoRespVos,
                },
              }),
            ],
            1
          )
        }),
        0
      ),
      _vm._l(_vm.pickupsPhotoRespVos, function (item, index) {
        return _c("div", { key: index }, [
          _c("div", { staticClass: "title" }, [
            _vm._v("第" + _vm._s(index + 1) + "次补拍照"),
          ]),
          _c(
            "div",
            { staticClass: "img-wrap" },
            _vm._l(item.photos, function (iItem, iIndex) {
              return _c(
                "div",
                { key: iIndex },
                [
                  _c("el-image", {
                    staticStyle: {
                      width: "100px",
                      height: "100px",
                      "margin-right": "20px",
                    },
                    attrs: { src: iItem, "preview-src-list": item.photos },
                  }),
                ],
                1
              )
            }),
            0
          ),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }